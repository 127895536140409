<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GiftList' }"
              >贈品管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>設定贈品照片</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center" style="flex-direction: column">
          <h4 class="font-weight-bold">設定贈品照片</h4>
        </div>
      </div>

      <div class="mb-4" v-if="gift != null">
        {{ gift.name }}
      </div>

      <img class="s-cropped-image" :src="image" />

      <!-- 發現手機板chrome 的click 似乎與draggable 處理event 衝突, 先簡單用touchstart 解掉 -->
      <ImageSelector @input="handleImagesChange($event)">
        <template #trigger="{ openFileSelector }">
          <button
            class="btn s-trigger-btn"
            :disabled="!canClick"
            @click="openFileSelector"
          >
            <template v-if="canClick">
              <div class="add-image-icon">＋</div>
              <div>更換圖片</div>
            </template>
            <template v-else> 上傳中... </template>
          </button>
        </template>
      </ImageSelector>
      <div class="d-flex justify-content-center">
        <!-- <b-button class="mr-3" variant="outline-success" @click="saveDraft">存成草稿</b-button> -->
        <!-- <b-button variant="success" @click="save">確定開團</b-button> -->
        <b-button variant="success" :disabled="!canClick" @click="gotoGiftList"
          >回商品列表</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ImageSelector from "@/components/ImageSelector";
import giftApi from "@/apis/gift";

export default {
  components: { ImageSelector },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => ({
    currentGiftId: null,
    gift: null,
    image: null,
    giftOptions: null,
    canClick: true,
    canUpload: true,
  }),
  created: function () {
    this.currentGiftId = this.$route.params.gift_id;
    this.getGiftDetail(this.currentGiftId, true);
  },
  watch: {
    async image(val) {
      if (val) {
        if (val.startsWith("data:image/jpeg;base64")) {
          await this.handleImagesChange(val);
        }
      }
    },
  },
  methods: {
    async handleImagesChange(imageBase64) {
      try {
        this.canClick = false;
        await giftApi.uploadImageByBase64(
          this.currentGiftId,
          imageBase64
        );

        this.getGiftDetail(this.currentGiftId, true);
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      } finally {
        this.canClick = true;
      }
    },

    async getGiftDetail(id, isUpdateImages = false) {
      const { data } = await giftApi.getGift(id);
      this.gift = data;
      if (isUpdateImages && this.gift) {
        this.image = this.gift.image_url;
      }
    },
    gotoGiftList() {
      this.$router.push({
        name: "GiftList"
      });
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: calc((100% / 3) - 0.75rem * 2);
  border: 1px solid #ddd;
  margin: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-image-icon {
  font-weight: 800;
  margin-bottom: 8px;
}
</style>
